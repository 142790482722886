<template>
  <main class="main page">

    <section class="section">
      <h1 class="section__heading">An error has occurred.</h1>
      <img src="@/assets/images/error.png" style="grid-column: span 12">
    </section>
  </main>
</template>

<style lang="scss" scoped>
</style>
